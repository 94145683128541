<template>
  <CModalExtended
      :title="`${$t('label.help')}: BAPLIE`" 
      color="dark" 
      size="lg" 
      :close-on-backdrop="false" 
      :show.sync="modalActive"
      @update:show="$emit('Close')"
      >
    <CRow>
      <CCol sm="12" v-if="modalActive">
        <video muted loop="true" controls class="mt-2 mb-2">
          <source 
            :src="getVideo" 
            type="video/mp4">
        </video>
      </CCol>
    </CRow>
    <template #footer>
      <CButton shape="square" color="wipe" class="d-flex align-items-center"  @click="$emit('Close')">
        <CIcon name="x" />
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>
  
<script>
function data() {
  return {
    modalActive: false,
  };
}
 
function getVideo() {
  return `${this.$store.getters["connection/getBase"]}${process.env.VUE_APP_BAPLIE_HELP_VIDEO}`;
}
  
export default {
  name: 'modal-help-baplie',
  props: { modal: Boolean,},
  data,
  methods: {

  },
  computed: {
    getVideo,
  },
  watch: {
    modal: function (val) {
      this.modalActive = val;
    },
  },
  
};
</script>
<style scoped>
  video { 
    width: 100%;
  }
</style>